<template>
    <div>
        <top>
            <div class="tc cfff">
                <div class="fs42 pt140">新闻咨询</div>
                <div class="fs20 mt20 " style="opacity: 0.8;">有关安迩平台新闻资讯</div>
            </div>
        </top>
        <div class="rowjc"    style=" min-height: 500px; margin-top: -130px;">
            <div class="box" style="padding: 0px 40px;">
                <div class="fs28 c333 pt30 fwb">{{info.title}}</div>
                <div class="fs16 c999 mt15">{{info.createTime}}</div>
                <div v-html="info.content"></div>
            </div>
        </div>
        <div style="height:60px;"></div>
        <bottom></bottom>
    </div>
</template>
<script>
    import top from '@/components/top'
    import bottom from '@/components/common/bottom'
    export default {
        components: {
            bottom, top
        },
        data() {
            return {
                info:{},//新闻详情
              
            }
        },
        created() {
           
        },
        mounted() {
           
           let id=this.$route.query.id
            this.getNewInfo(id)//新闻详情
        },
        computed: {
         
        },
        methods: {
            getNewInfo(id){
                this.$axios({
                    url:"getMessageInfo",
                    data:{
                        id
                    }
                }).then(res=>{
                    console.log(res.data.data)
                    if(res.data.code==0){
                        this.info=res.data.data
                    }else{
                        this.info={}
                    }
                })
            }
        },
    }
</script>
<style>
    #app {
        background-color: #F2F5F7;
    }

    .box {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 20px;

    }
    .bottomQ:not(:last-child){
        border-bottom: 1px dashed #D7D7D7;
    }
    .bottomQ:hover{
        cursor: pointer;
    }
</style>